import { createStore, combineReducers} from 'redux';
import { reducer as formReducer } from 'redux-form';
import productDropdownReducer from '../Components/MainNavBar/reducer';
import compareReducer from '../Components/Search/Compare/reducer';
import IPReducer from '../Components/Homepage/reducer';
import { addToCardReducer, productDetailedReducer, pageLinksReducer } from '../Components/Product/reducer';
import { offerFilterReducer, brandFilterReducer, collectionFilterReducer, categoryFilterReducer, colourFilterReducer, itemsPerPageReducer, searchReducer, sortByReducer, pageNumberReducer } from '../Components/Search/reducer';
import { expirationTimeReducer, loginReducer, usernameReducer } from '../Components/Login/reducer';
import { accountsReducer, billingAddressReducer, deliveryAddressReducer, basketReducer, orderReducer } from '../Components/Account/reducer';
import brandListReducer from '../Components/BrandsPage/reducer';


const rootReducer = combineReducers({
    accessToken: loginReducer,
    account: accountsReducer,
    deliveryAddresses: deliveryAddressReducer,
    addToCart: addToCardReducer,
    basket: basketReducer,
    billingAddress: billingAddressReducer,
    brandFilter: brandFilterReducer,
    collectionFilter: collectionFilterReducer,
    brandList: brandListReducer,
    categoryFilter: categoryFilterReducer,
    colourFilter: colourFilterReducer,
    compare: compareReducer,
    expirationTime: expirationTimeReducer,
    form: formReducer,
    IP: IPReducer,
    orders: orderReducer,
    offerFilter: offerFilterReducer,
    pageLinks: pageLinksReducer,
    perPage: itemsPerPageReducer,
    pageNumber: pageNumberReducer,
    product: productDetailedReducer,
    productDropdown: productDropdownReducer,
    searches: searchReducer,
    sortBy: sortByReducer,
    username: usernameReducer
});

const store = createStore(
    rootReducer, 
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default store;