import * as actionTypes from '../../Store/actions';

export const accountsReducer = (state=[], action) => {
    switch(action.type) {
        case actionTypes.LOAD_MAIN_ACCOUNT_DETAILS:
            return action.payload;

        default:
            return state;
    }
}

export const basketReducer = (state=[], action) => {
    switch(action.type) {
        case actionTypes.LOAD_BASKET_ITEMS:
            return action.payload;
        default:
            return state;
    }
}

export const billingAddressReducer = (state=[], action) => {
    switch(action.type) {
        case actionTypes.LOAD_BILLING_ADDRESS_DETAILS:
            return action.payload;
        default:
            return state;
    }
}

export const deliveryAddressReducer = (state=[], action) => {
    switch(action.type) {
        case actionTypes.LOAD_DELIVERY_ADDRESS_DETAILS:
            return action.payload;
        default:
            return state;
    }
}

export const orderReducer = (state=[], action) => {
    switch(action.type) {
        case actionTypes.LOAD_ORDER_DETAILS:
            return action.payload;

        default:
            return state;
    }
}