import React from 'react';

const Spinner = (props) => {
     return (
          <div style={{ marginTop: `${props.margin}` }} className="Spinner d-flex justify-content-center w-100">
               <div className="Box" style={{ fontSize: props.fontSize, marginBottom: props.marginBottom }}>
                    <div className="Loader"></div>
               </div>
          </div>
          
     );
}

export default Spinner;