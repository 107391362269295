// Account Actions
export const LOAD_MAIN_ACCOUNT_DETAILS = "LOAD_MAIN_ACCOUNT_DETAILS";
export const LOAD_BILLING_ADDRESS_DETAILS = "LOAD_BILLING_ADDRESS_DETAILS";
export const LOAD_DELIVERY_ADDRESS_DETAILS = "LOAD_DELIVERY_ADDRESS_DETAILS";
export const LOAD_ORDER_DETAILS = "LOAD_ORDER_DETAILS";
export const LOAD_BASKET_ITEMS = "LOAD_BASKET_ITEMS";

// Header Action
export const LOAD_OFFER_FILTER_ITEM = "LOAD_OFFER_FILTER_ITEM";
export const LOAD_CATEGORY_FILTER_ITEM = "LOAD_CATEGORY_FILTER_ITEM";
export const LOAD_BRAND_FILTER_ITEM = "LOAD_BRAND_FILTER_ITEM";
export const LOAD_COLLECTION_FILTER_ITEM = "LOAD_COLLECTION_FILTER_ITEM";
export const LOAD_COLOUR_FILTER_ITEM = "LOAD_COLOUR_FILTER_ITEM";
export const LOAD_IP_UPDATE = "LOAD_IP_UPDATE";
export const LOAD_ITEMS_PER_PAGE = "LOAD_ITEMS_PER_PAGE";
export const LOAD_SORT_BY_ITEM = "LOAD_SORT_BY_ITEM";
export const LOAD_PAGE_NUMBER = "LOAD_PAGE_NUMBER";


// Login Actions
export const STORE_ACCESS_TOKEN = "STORE_ACCESS_TOKEN";
export const STORE_USERNAME = "STORE_USERNAME";
export const STORE_EXPIRATION_TIME = "STORE_EXPIRATION_TIME";


// Navbar Actions
export const LOAD_PRODUCT_DROPDOWN = "LOAD_PRODUCT_DROPDOWN";
export const LOAD_MANUFACTURER_CODE_URL = "LOAD_MANUFACTURER_CODE_URL";
export const LOAD_HIGH_LOW_OPTION = "LOAD_HIGH_LOW_OPTION";
export const LOAD_LOW_HIGH_OPTION = "LOAD_LOW_HIGH_OPTION";
export const LOAD_ALPHABETICAL_OPTION = "LOAD_ALPHABETICAL_OPTION";

// Product Actions
export const ADD_TO_CART = "ADD_TO_CART";
export const LOAD_DETAILED_PRODUCT = "LOAD_DETAILED_PRODUCT";
export const LOAD_PAGE_LINKS = "LOAD_PAGE_LINKS";

// Search Actions
export const LOAD_SEARCHES = "LOAD_SEARCHES";

//Compare Actions
export const LOAD_COMPARE_PRODUCT = 'LOAD_COMPARE_PRODUCT';

// Brands Actions
export const LOAD_BRAND_LIST = 'LOAD_BRAND_LIST';
