import * as actionTypes from '../../Store/actions';

const productDropdownReducer = (state = [], action) => {
    switch (action.type) {
        case actionTypes.LOAD_PRODUCT_DROPDOWN:
            return action.payload
        default:
            return state
    }
}
 export default productDropdownReducer;
