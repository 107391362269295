import * as actionTypes from '../../Store/actions';

export const brandsListReducer = (state=[], action) => {
    switch(action.type) {
        case actionTypes.LOAD_BRAND_LIST:
            return action.payload;

        default:
            return state;
    }
}

export default brandsListReducer;