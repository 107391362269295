import * as actionTypes from '../../Store/actions';

const IPReducer = (state=[], action) => {
    switch(action.type) {
        case actionTypes.LOAD_IP_UPDATE:
            return action.payload
        default:
            return state
    }
}

export default IPReducer;