import * as actionTypes from '../../Store/actions';

export const expirationTimeReducer = (state=[], action) => {
    switch(action.type) {
        case actionTypes.STORE_EXPIRATION_TIME:
            return action.payload
        default:
            return state
    }
}

export const loginReducer = (state=[], action) => {
    switch(action.type) {
        case actionTypes.STORE_ACCESS_TOKEN:
            return action.payload
        default:
            return state
    }
}

export const usernameReducer = (state=[], action) => {
    switch(action.type) {
        case actionTypes.STORE_USERNAME:
            return action.payload
        default:
            return state
    }
}

