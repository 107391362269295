import * as actionTypes from '../../Store/actions';

export const addToCardReducer = (state=[], action) => {
    switch(action.type) {
        case actionTypes.ADD_TO_CART:
            return action.payload
        default:
            return state
    }
}

export const pageLinksReducer = (state=[], action) => {
    switch(action.type) {
        case actionTypes.LOAD_PAGE_LINKS:
            return action.payload
        default:
            return state
    }
}

export const productDetailedReducer = (state=[], action) => {
    switch(action.type) {
        case actionTypes.LOAD_DETAILED_PRODUCT:
            return action.payload
        default:
            return state
    }
}

