import * as actionTypes from '../../Store/actions';

export const offerFilterReducer = (state=[], action) => {
    switch(action.type) {
        case actionTypes.LOAD_OFFER_FILTER_ITEM:
            return action.payload
        default:
            return state;
    }
}

export const brandFilterReducer = (state=[], action) => {
    switch(action.type) {
        case actionTypes.LOAD_BRAND_FILTER_ITEM:
            return action.payload
        default:
            return state;
    }
}

export const collectionFilterReducer = (state=[], action) => {
    switch(action.type) {
        case actionTypes.LOAD_COLLECTION_FILTER_ITEM:
            return action.payload
        default:
            return state;
    }
}

export const colourFilterReducer = (state=[], action) => {
    switch(action.type) {
        case actionTypes.LOAD_COLOUR_FILTER_ITEM:
            return action.payload
        default:
            return state;
    }
}

export const categoryFilterReducer = (state=[], action) => {
    switch(action.type) {
        case actionTypes.LOAD_CATEGORY_FILTER_ITEM:
            return action.payload
        default:
            return state;
    }
}

export const itemsPerPageReducer = (state=[], action) => {
    switch(action.type) {
        case actionTypes.LOAD_ITEMS_PER_PAGE:
            return action.payload
        default:
            return state;
    }
}

export const pageNumberReducer = (state=[], action) => {
    switch(action.type) {
        case actionTypes.LOAD_PAGE_NUMBER:
            return action.payload
        default:
            return state;
    }
}

export const searchReducer = (state=[], action) => {
    switch(action.type) {
        case actionTypes.LOAD_SEARCHES:
            return action.payload
        default:
            return state;
    }
}

export const sortByReducer = (state=[], action) => {
    switch(action.type) {
        case actionTypes.LOAD_SORT_BY_ITEM:
            return action.payload
        default:
            return state;
    }
}