import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import Favicon from 'react-favicon';
import faviconImage from './images/RKWLogo.PNG';
import Aux from './hoc/ReactAux';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-image-lightbox/style.css';
import './index.scss';
import store from './Store/store';
import Spinner from './Components/UI/Spinner/spinner';

const App = lazy(() => import('./App'));

const app = (
    <Provider store={store}>
        <HashRouter>
            <Aux>
                <Favicon url={faviconImage} />
                <Suspense fallback={<Spinner margin="5rem" />}>
                    <App />
                </Suspense>
            </Aux>
        </HashRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));